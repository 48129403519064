<template>
  <div class="verify">
    <el-col :xs="{ span: 20, offset: 2 }" :sm="{ span: 12, offset: 6 }" :lg="{ span: 8, offset: 8 }">
      <el-card class="content">
        <div slot="header">
          <span>{{ $t('verify.applyList') }}</span>
        </div>
        <div v-if="success" class="success-info">
          <i class="el-icon-check" style="color: #67C23A" />
          <p>{{ $t('common.applySucc') }}</p>
        </div>
        <el-form v-loading="loading" v-if="!success" label-position="right" label-width="130px" size="small">
          <el-form-item v-for="(item, i) of applyList" :key="item.id" :label="serviceTitle(item.serviceCode)">
            <el-radio-group v-model="applyList[i].status">
              <el-radio-button label="approved">{{ $t('verify.approve') }}</el-radio-button>
              <el-radio-button label="rejected">{{ $t('verify.reject') }}</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item style="text-align: right; margin-bottom: 0;">
            <el-button :disabled="applyList.length == 0" type="primary" @click="approve">{{ $t('common.submit')
            }}</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </el-col>
  </div>
</template>

<script>
import { preview, approve } from "@/api/admin";
import { serviceI18n } from "@/filters";

export default {
  data() {
    return {
      loading: false,
      applyList: [],
      success: false
    };
  },
  methods: {
    approve() {
      this.loading = true;
      let params = {};
      this.applyList.forEach(e => {
        if (!params[e.status]) {
          params[e.status] = [];
        }
        params[e.status].push(e.token);
      });
      approve({ statusMapping: params })
        .then(response => {
          if (response.success) {
            this.success = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    serviceTitle(code) {
      return this.$t(serviceI18n(code))
    }
  },
  mounted() {
    preview(this.$route.query).then(response => {
      if (response.success) {
        let l = response.result || [];
        l.forEach(e => {
          e.status = "approved";
        });
        this.applyList = l;
      }
    });
  }
};
</script>

<style lang="scss" scoped>
.verify {
  padding-top: 20vh;

  .success-info {
    text-align: center;

    i {
      font-size: 2em;
    }
  }
}
</style>